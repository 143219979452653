/* You can add global styles to this file, and also import other style files */
@import '~@angular/cdk/overlay-prebuilt.css';
html,
body {
  color: #313336;
  background-color: #1a1f24;
  width: 100%;
  height: 100%;
  position: relative;
}
html {
  min-height: 100%;
  text-align: center;
  overflow: auto;
}
html body {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  overflow: auto;
  height: 100%;
  min-height: 100%;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  position: relative;
}
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  font-size: 20px;
  font-weight: 600;
  border-radius: 25px;
  box-shadow: none;
  height: 50px;
  min-width: 175px;
  margin: 40px 0 16px 0;
}
app-root app-step4 .mat-tab-header {
  border-radius: 10px 10px 0 0;
}
app-root .mat-tab-label {
  font-size: 15px;
  font-weight: 600;
  color: #313336;
}
app-root .mat-form-field.mat-focused .mat-form-field-label {
  color: #1774cc;
}
app-root .mat-form-field.mat-focused .mat-form-field-ripple {
  color: #1774cc;
  background-color: #1774cc;
}
h1 {
  font-weight: 600;
}
header {
  min-width: 320px;
  background: #fff;
  height: 72px;
  border-bottom: solid 1px #666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .img {
  cursor: pointer;
  flex: 0;
  margin: 14px;
  margin-right: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 41px;
  min-width: 180px;
}
html[dir='rtl'] header .img {
  background-position: right;
}
app-root header .mat-button.help-link {
  margin-right: 14px;
  font-size: 16px;
  line-height: 22px;
  color: #1774cc;
  font-weight: normal;
}
app-root header .mat-button.help-link /deep/ .mat-button-focus-overlay {
  background-color: transparent;
}
app-root header .mat-button.sign-up /deep/ .mat-button-focus-overlay {
  background-color: transparent;
}
.conditionalWidth {
  max-width: 450px;
}
.earlyVisitBoxWidth {
  max-width: 476px;
}
#wrapper #page-contents a {
  text-decoration: none;
}
html[dir='rtl'] #wrapper #page-contents a {
  right: auto;
  left: 0;
}
#wrapper #page-contents .signup {
  margin: auto;
  width: 128px;
  height: 128px;
}
mat-form-field {
  width: 100%;
}
.mat-tab-body-content {
  padding: 20px 30px;
}
.mat-tab-header {
  background-color: #f5f8fa;
}
.mat-tab-labels .mat-tab-label {
  color: #313336;
  opacity: 1;
  width: 50%;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  color: #1774cc;
  background-color: #1774cc;
}
.visit-tabs .mat-ink-bar {
  height: 6px;
}
app-root .mat-button,
app-root .mat-raised-button,
app-root .mat-icon-button,
app-root .mat-stroked-button,
app-root .mat-flat-button,
app-root .mat-fab,
app-root .mat-mini-fab {
  color: #1774cc;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
}
@media only screen and (max-width: 768px) {
  app-root .unsupported-browser-copy-button.mat-flat-button.mat-primary {
    width: 85%;
  }
}
app-root app-step4 .entry-field-contents .mat-button {
  font-size: 14px;
  line-height: 30px;
}
@-moz-document url-prefix() {
  app-root app-step4 .entry-field-contents .mat-button {
    line-height: 28px;
  }
}
.mat-form-field {
  line-height: normal;
}
@media only screen and (max-width: 768px) and (max-width: 1024) {
  header {
    box-shadow: 0 0 5px 0 #ccc;
    border-bottom: 0;
  }
  #wrapper {
    margin: 0 auto;
    max-width: 100vw;
  }
  #wrapper.without-tabs {
    padding-top: 20px;
  }
}
.logo {
  padding: 5px;
  background-image: url('assets/common/powered-by.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 24px;
  margin-bottom: 40px;
  box-sizing: content-box;
}
.minorText {
  display: block;
  font-size: 14px;
}
.step-heading {
  margin-bottom: 8px;
}
.step-subheading {
  margin-top: 0px;
}
.required-item:after {
  content: ' *';
}
.cdk-overlay-dark-backdrop {
  background-color: rgba(255, 255, 255, 0.85);
}
.start-a-visit-form .mat-tab-body.mat-tab-body-active {
  overflow-y: inherit;
}
.blue-text {
  font-family: 'Open Sans';
  color: #1774cc;
}
:not(.mat-expanded) .mat-content {
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-image: url('/assets/help/right.svg');
}
.mat-expanded .mat-content {
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-image: url('/assets/help/down.svg');
}
.small-input-width > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix {
  width: auto;
}
.small-input.mat-input-element {
  width: 70px;
  text-align: center;
}
.small-input-width {
  width: auto;
  margin-right: 5px;
}
.padding-style .mat-button-wrapper {
  padding: 11px 24px 12px 24px;
}
.rectangle mat-select:not(.mat-select-disabled) .mat-select-value {
  color: #141414;
}
.rectangle .mat-select-value {
  height: 22px;
  font-size: 16px;
}
.rectangle .mat-select-value-text {
  padding: 10px 0 32px 7px;
}
.rectangle .mat-select-arrow {
  margin-right: 10.9px;
}
.mat-select-panel .mat-option.option-style.mat-selected:not(.mat-option-multiple) {
  background-color: #d0eefe;
}
.mat-select-panel .mat-option:last-child {
  border-bottom: 2px solid #1774cc;
}
.mat-primary .mat-option.option-style.mat-selected:not(.mat-option-disabled) {
  color: #141414;
}
.mat-select .mat-select-arrow-wrapper {
  display: block;
}
[id^='cdk-overlay'] {
  transform: none !important;
}
.mat-select-panel {
  min-width: auto !important;
}
.mat-select-arrow-wrapper {
  padding-top: 12px;
  background-image: url('./assets/common/invalid-name.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.mat-select-arrow {
  opacity: 0;
}
.mat-select-panel-wrap .mat-select-panel {
  border-radius: 0;
  max-width: inherit;
}
.cancel-visit-dialog-container .mat-dialog-container {
  border-radius: 10px;
}
.cdk-overlay-pane .mat-dialog-container {
  border-radius: 10px;
}
.mat-label-style {
  font-size: 16px;
  font-weight: normal;
}
.send-invite-via {
  min-width: 127px;
  height: 15px;
  font-size: 16px;
  font-weight: normal;
  padding-left: 10px;
}
html[dir='ltr'] .align-dir {
  text-align: left;
}
html[dir='rtl'] .align-dir {
  text-align: right;
}
.backdropBackground {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
html[dir='rtl'] .help-resource {
  display: none;
}
.material-icons.fs-inherit {
  font-size: inherit;
}
.material-icons.new-tab-icon {
  font-size: inherit;
  margin-left: 0.2rem;
}
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.awl-participant-list-tabs p.step-form-hint,
.intake-wrapper p.step-form-hint {
  font-size: 14px;
  position: relative;
  text-align: center !important;
  color: #bec3cc;
  margin-top: 1.2em !important;
}
.global-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.global-progress-bar div.progress {
  margin: 0;
}
aw-responsive-layout {
  height: 100%;
  display: block;
}
aw-responsive-layout aw-responsive-layout-type2 aw-responsive-layout-base {
  height: 100%;
}
aw-footer .aw-footer {
  padding: 1rem 2rem !important;
  background: var(--aw-footer-bg);
}
@media only screen and (max-width :991px) {
  aw-footer .aw-footer {
    padding: 1rem 1rem !important;
  }
}
aw-footer .aw-footer .footer-container {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
aw-footer .aw-footer aw-copyright {
  box-sizing: border-box;
  margin-bottom: 1rem;
}
aw-footer .aw-footer aw-copyright p {
  color: var(--aw-footer-color);
  font-size: var(--aw-footer-font-size);
  line-height: 16px;
  margin: 0px !important;
  text-align: left;
}
aw-footer .aw-footer .footer-links {
  box-sizing: border-box;
  line-height: 15px;
  margin-bottom: 1rem;
}
aw-footer .aw-footer .footer-links a {
  font-size: var(--aw-footer-font-size);
  margin-right: 1rem;
  line-height: 16px;
}
aw-footer .aw-footer .footer-links a:last-child {
  margin-right: 0px !important;
}
aw-footer .aw-footer .footer-logo {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  position: relative;
  margin-top: auto;
}
aw-footer .aw-footer .footer-logo:after {
  content: '';
  display: block;
  width: calc(100% + 4rem);
  height: 1px;
  background: var(--aw-footer-logo-border);
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: -2rem;
  margin-right: -2rem;
}
@media only screen and (max-width :991px) {
  aw-footer .aw-footer .footer-logo:after {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
aw-footer .aw-footer .footer-logo aw-logo {
  display: block;
  width: 100%;
  max-width: 84px;
}
aw-responsive-layout-base aw-footer {
  display: block;
  position: relative;
  bottom: 0px;
  left: 0px;
  margin-top: auto;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: auto;
}
@media only screen and (max-width :991px) {
  aw-responsive-layout-base aw-footer {
    padding-top: 1.5rem;
  }
}
aw-responsive-layout-base aw-footer .aw-footer {
  padding: 1rem 2rem 0px !important;
}
@media only screen and (max-width :991px) {
  aw-responsive-layout-base aw-footer .aw-footer {
    padding: 1rem 1rem 0px !important;
  }
}
aw-responsive-layout-base aw-footer .aw-footer .footer-logo {
  display: none;
}
app-timeout-modal .theme-light {
  background-color: transparent;
}
